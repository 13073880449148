<template>
  <section class='section'>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Reportes</h1>
        </div>
      </div>
    </nav>
    <div class="box">
      <div class="columns is-multiline">
        <div class="column is-4">
          <label class="label">
            Fecha
          </label>
          <div class="container">
            <v-date-picker
              class="date__select"
              :class="{ 'is-danger-date' : errorDate }"
              mode='range'
              v-model='date'
              :max-date="today"
            />
            <span class="material-icons">
              date_range
            </span>
          </div>
          <p class="help is-danger"> {{vverrors.first('date')}} </p>
        </div>
        <div class="column is-4">
          <label class="label">
            Reportes
          </label>
          <div class="control">
            <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('selectReport') }">
              <select
                class="select"
                v-model="selectReport"
                name="selectReport"
                v-validate="'required|excluded:-1'"
                data-vv-as="reporte"
              >
              <option disabled value="-1">
                Seleccione el reporte
              </option>
              <option value="1">Reporte ejecutivo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="container__btn">
            <button
              class="button button-givu is-fullwidth"
              :class="{'is-loading': isLoading}"
              @click="generateReport"
            >
              Generar Reporte
            </button>
          </div>
        </div>
        <div class="column is-2">
          <div class="container__btn" v-if="reportList.length !== isEmpty">
            <button
              class="button is-fullwidth"
              :class="{'is-loading': loading}"
              @click="getCsvDownload"
            >
              Descargar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="reportList"
          :loading=isLoading
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="isLoading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixin from '../../mixin/validateDate'
import moment from 'moment'

export default {
  mixins: [mixin],
  name: 'Reportes',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      date: {},
      today: moment().format('MMMM-DD-YYYY, h:mm:ss a'),
      selectReport: -1,
      page: 1,
      pages: '',
      errorDate: false,
      loading: false,
      isLoading: false,
      reportList: [],
      isEmpty: 0,
      columnsData: [
        {
          id: 'dates',
          header: 'Fecha',
          class: 'column-mobile',
          accessor: (row) => this.formatDateTimestamp(row.dates)
        },
        {
          id: 'totalRegisteredUsers',
          header: 'Registros por día',
          class: 'column-mobile',
          accessor: (row) => row.total_registered_users
        },
        {
          id: 'totalRegisteredProducts',
          header: 'Productos subidos por día',
          class: 'column-mobile',
          accessor: (row) => row.total_registered_products
        },
        {
          id: 'totalSalesCompleted',
          header: 'Ventas concretadas',
          class: 'column-mobile',
          accessor: (row) => row.total_sales_completed
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getExecutiveReport', 'csvDownloadReports', 'addTemporalNotification']),
    async generateReport () {
      if (Object.keys(this.date).length === 0 && this.date.constructor === Object && await this.$validator.validateAll()) {
        this.errorDate = true
      } else {
        this.errorDate = false
        this.isLoading = true
        this.page = 1
        let startDate = moment(this.date.start).format('YYYY-MM-DD 00:00:00')
        let endDate = moment(this.date.end).format('YYYY-MM-DD 23:59:59')

        this.getExecutiveReport({ startDate, endDate, filter: this.selectReport, page: this.page })
          .then((resp) => {
            if (resp) {
              this.reportList = resp.reports
              this.pages = resp.totalPages
            }
            this.isLoading = false
          })
      }
    },
    paginator (page) {
      this.page = page
      let startDate = moment(this.date.start).format('YYYY-MM-DD 00:00:00')
      let endDate = moment(this.date.end).format('YYYY-MM-DD 23:59:59')
      this.isLoading = true

      this.getExecutiveReport({ startDate, endDate, filter: this.selectReport, page: page })
        .then(resp => {
          this.isLoading = false
          this.reportList = resp.reports
          this.pages = resp.totalPages
        })
    },
    getCsvDownload () {
      this.loading = true
      let startDate = moment(this.date.start).format('YYYY-MM-DD 00:00:00')
      let endDate = moment(this.date.end).format('YYYY-MM-DD 23:59:59')

      this.csvDownloadReports({ startDate, endDate, filter: this.selectReport })
        .then(resp => {
          if (resp) {
            const exportName = 'Export executiveReport.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .container {
    display: flex;
    align-items: center;
  }
  .date__select {
    width: 100%;
  }
  .material-icons {
    margin: 0 1rem;
  }
  .container__btn {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  .button {
    margin: .4rem 0;
  }
  .is-danger-date {
    border: 1px solid #ff3860;
    border-radius: 4px;
  }
</style>
